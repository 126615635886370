import DynamicComponent, { DynamicComponentProps } from "../DynamicComponent";
import { storyblokEditable } from "@storyblok/react";
import { Flex } from "@chakra-ui/react";
import { convertColor } from "../../utils/misc";

type SignInVisibility = "everyone" | "signedIn" | "notSignedIn"

export interface BoxProps extends DynamicComponentProps<BoxBlok> {

}

const Box = ({ blok, globalState, setGlobalState, locationState , ppm365, locale}: BoxProps) => {
  const horizontalAlign = blok.alignment === 'center' ? 'center' : blok.alignment === 'left' ? 'flex-start' : 'flex-end';
  const verticalAlign = blok.verticalAlignment === 'middle' ? 'center' : blok.verticalAlignment === 'top' ? 'flex-start' : 'flex-end';
  const visibility: SignInVisibility = blok.signInVisibility ? blok.signInVisibility : "everyone" //default to visible

  const bgColor = convertColor(blok.backgroundColor, blok.backgroundSaturation)
  const borderColor = convertColor(blok.borderColor, blok.borderSaturation)
  return (
    (
      (visibility === "everyone") ||
      (visibility === "signedIn" && globalState.user?.email) ||
      (visibility === "notSignedIn" && !globalState.user?.email)
    ) &&
    <Flex
      {...storyblokEditable(blok)}
      border={`solid`}
      borderWidth={blok.borderWidth ? blok.borderWidth : 0}
      borderColor={borderColor}
      borderRadius={blok.borderRadius}
      shadow={blok.shadow}
      w={blok.width ? blok.width : 'full'}
      maxW={blok.maxWidth}
      bgColor={bgColor}
      flexDirection="column"
      mt={blok.marginTop}
      mb={blok.marginBottom}
      px={blok.paddingX}
      py={blok.paddingY}
      alignItems={horizontalAlign}
      justifyContent={verticalAlign}
      overflow="hidden"
    >
      {blok.anchor && <a id={blok.anchor} style={{position: 'relative', top: '-40px'}}></a>}
      {
        blok.content?.map((nestedBlok, index) => (
          <DynamicComponent 
          blok={nestedBlok} 
          key={index} 
          globalState={globalState} 
          setGlobalState={setGlobalState} 
          locationState={locationState}
          ppm365={ppm365}
          locale={locale}
        />
        ))
      }
    </Flex>
  );
}

export default Box;
